@media all and (max-width: 1300px) {
	section {overflow: hidden;}
	header{
		padding: 2.5rem 3.2rem 0;

		.nav, .lang-head, .search-head{
			margin-right: 0;
			display: block;
		}
		.burger-nav{
			display: block;
			position: relative;
			width: 3rem;
			height: 3rem;
			cursor: pointer;
			z-index: 2;
			@include position(absolute, 3rem 0 null null);

			span{
				display: block;
				height: .3rem;
				background: $color-initial;
				@include transition(.2s all ease-out);
				@include position(absolute, 0 0 null);

				&:nth-child(2){top:1rem;}
				&:last-child{top:2rem;}
			}

			&.close{
				span{
					&:first-child{
						top:1rem;
						transform: rotate(45deg);
					}
					&:nth-child(2){opacity: 0;}
					&:last-child{
						top:1rem;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.search-head{
			z-index: 6; width: 28.1rem; 
			@include position(fixed, null 4.7rem 6rem null);

			.btn-search{display: none;}
			.drop-search{
				display: block;
				position: relative;
				top: auto;
				right: auto;

				form,input{margin-bottom: 0;}
				input{
					padding-right: 3rem;
					padding-left: 0;
					background: url("../images/material/icon-search.svg") no-repeat right center;
					&.go {background: none;} 
				}
			}
		}
		.nav-right{
			width: 37.5rem;
			background: #1A1A1A;
			padding: 9.6rem 4.7rem 12rem 4.7rem;
			box-sizing: border-box;
			display: none;
			margin-top: 0;
			overflow: auto; 
			@include position(fixed, 0 0 0 null);
			&:after {
				position:fixed; width: 37.5rem; height: 12rem; right: 0; bottom: 0; 
				content: ""; 
				z-index: 5;
				background: #1A1A1A;
			}
			a{
				display: block;
				margin-bottom: 3.7rem;
			}
			.lang-head{
				a{
					display: inline-block;
					margin-bottom: 0;
				}
			}
			.nav{
				a{
					margin-bottom: 0; height: auto; line-height: 1.5;  
					&:before {display:none;}
				}
				> li{
					display: block;
					padding-bottom: 0;
					margin-bottom: 2rem;

					>ul{
						opacity: 1;
						visibility: visible;
						transform: translateX(0);
						margin: 0;
						background: transparent;
						padding: 1rem 0 0 1.5rem;
						display: none;
						transition: none;
						width: 100%; 
						box-sizing: border-box; 
						@include position(relative, auto);

						a{color: $color-initial;}
						> li {
							padding: 0; border-bottom: 0;
							a {padding: 5px 0;}
						}
					}

					&.parent{
						&:before{
							content:"";
							width: 3rem;
							height: 1.5rem;
							background: url('../images/material/arrow-select.svg')no-repeat center;
							transition: all .2s ease-out;
							@include position(absolute, 0 0 null nul);
						}
						&.act {
							&:before {
								transform:rotate(180deg);
							}
						}
					}
				}
			}
		}
		&.float{
			padding: 1rem 3.2rem;
			.search-head{
				.drop-search{
					input{
						background: url("../images/material/icon-search.svg") no-repeat right center;
					}
				}
			}	
			.nav-right {
				.nav {
					a {
						height: auto; line-height: 1.5; 
					}
				}
			}
		}
	}

	.side-content{
		width: 57.2rem;
		margin-left: auto;
		margin-right: auto;
		flex-wrap: wrap;

		.title-side, .content-side{
			flex-basis: 100%;
			max-width: 100%;
		}
		.title-side{
			margin-bottom: 4rem;
		}
	}
	.mid-desktop{
		width: 72rem;
	}
	.small-desktop{
		width: 57.2rem;
	}
	.full-tablet{
		.row,&.row{
			flex-wrap: wrap;
			
			.column{
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}
	.thumb-list {
		&.committee-list {
			.column {}
			figcaption {			
				h4 {
					min-height: 50px; 
				}
			}
		}
	}

	.row.row-intro {
		align-items: flex-start;
		.column {
			&.column-41 {flex: 0 0 47%; max-width: 47%;}
			&.column-51 {flex: 0 0 51%; max-width: 51%;} 
			h3 {font-size: 2rem;}
		}
	}


	footer{
		padding: 5.8rem 3.2rem;

		.logo-foot{margin-bottom: 3rem;}
		.sosmed-box{margin-bottom: 3rem;}
	}
}

@media all and (max-width: 1020px) {
	.banner{
		figcaption{
			width: 100%;
			padding: 0 3rem;
		}
	}
	.banner-large{
		figcaption{
			width: 100%;
			padding: 0 3.2rem;
		}
	}
	.mid-desktop,.wrapper-middle,.wrapper-small{
		width: 90%;
		max-width: 56rem;
	}
}