.popup{
	z-index: 100;
	background: rgba(0,0,0,0.8);
	display: none;
	align-items: center;
	overflow: auto; 
	@include position(fixed, 0);

	.inner-popup{
		width: 77rem;
		background: $color-initial;
		margin: auto auto auto auto;
		position: relative; 
		.close-popup{
			width: 4rem;
			height: 4rem;
			background: #242424;
			cursor: pointer;
			border: none;
			z-index: 2;
			@include position(absolute, 0 0 null null);

			&:before,&:after{
				content:"";
				width: 1.5rem;
				height: .1rem;
				background: #707070;
				display: block;
				transform: rotate(45deg);
				margin: auto;
				@include position(absolute, 0);
			}
			&:after{
				transform: rotate(-45deg);
			}

			&:hover{
				&:before,&:after{
					background: $color-initial;
				}
			}
		}
	}
}