@media all and (max-width: 1600px) {
	.scroll-down{
		&:before{
			height: 10rem;
		}
	}

	.about-home{
		padding: 10rem 0;
	}
	
	.slider-product {
		figure {height:calc(100vh - 8rem);}
	}
	.product-home {
		.indicator-slider-product {			
			@include transform(translateY(-50%) scale(.9));
		}
		.text-slider-product {			
			@include transform(translateY(-50%) scale(.9));
		}
	}

}