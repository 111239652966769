@media all and (max-width: 740px) {
	.business-pillar{
		flex-wrap: wrap;
		.text,figure{
			width: 100%;
		}
		.text{
			padding: 5rem 2rem;
			order: 2;
		}
		figure{order: 1;}
	}
}