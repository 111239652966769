@media all and (max-width: 1300px) {
	.sus4{
		flex-wrap: wrap;

		.list{
			flex-basis: 50%;
			max-width: 50%;

			figure{
				img{
					max-height: 57rem;
					object-fit: cover;
				}
			}
		}
	}

	.side-urban{
		border-bottom: none;
		
		.list{
			figcaption{
				padding-right: 9rem;
			}

			&:nth-child(even){
				figcaption{
					padding-right: 0;
					padding-left: 9rem;
				}
			}
		}
	}
}

@media all and (max-width: 1020px) {
	.side-urban{
		.list{
			margin-bottom: 4rem;
			
			figcaption{
				padding-right: 0;
			}
			&:nth-child(even){
				figcaption{
					padding-left: 0;
				}
			}
		}
	}
}