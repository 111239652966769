body.lock {overflow: hidden;}
/* header
----------------------------------------------------------------------------------------------*/
header{
	z-index: 100;
	padding-top: 2.5rem;
	@include position(fixed, 0 0 null 0);

	.wrapper{
		display: flex;
		justify-content: space-between;
	}
	.nav-right{
		margin-top: 5rem;
	}
	.burger-nav{display: none;}
	.nav, .lang-head, .search-head{
		display: inline-block;
		vertical-align: top;
	}
	.lang-head{
		margin-right: 3rem;
		a{
			text-transform: uppercase;
			font-size: 1.2rem;
			color: $color-initial;
			display: inline-block;
			vertical-align: middle;
			&:hover {opacity:.6;} 
			&.active {font-weight: bold; opacity:.6;}  
		}
	}
	.nav{
		margin:0 3rem 0 0;
		li{
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			&:hover {
				a {
					&:before {width: 20px;}
				}				
			}						
		}
		a{
			text-transform: uppercase;
			font-size: 1.2rem;
			color: $color-initial;
			display: flex; align-items: center; 
			position: relative;
			height: 25px; line-height: 25px;
			&:before {
				position: absolute; width: 0; height: 3px; content: ""; 
				background:$color-initial; bottom: -1px; left: 0;  
				transition: all .2s ease-out;
			}
			&:hover,
			&.active {
				&:before {width: 20px;}
			}
		}
		> li{
			margin-right: 3rem;
			position: relative;
			padding-bottom: 0rem;
			> ul{
				width: 16rem;
				margin: 0 auto;
				background: #000; 
				padding: 0rem;
				transform: translateX(-50%);
				opacity: 0;
				visibility: hidden;
				transition: .2s all ease-out;
				box-shadow: 0 .5rem 1rem 0 rgba(0,0,0,0.1);
				margin-top: 1px; 
				@include position(absolute, 80% null null 50%);
				a{
					color: #a6a9aa;
					&:hover,
					&.active {
						&:before {width: 20px;}
					}
				} 
				> li{
					display: block;
					margin-bottom: 0rem;
					border-bottom: 1px solid #333; padding: 0 2rem 0 2.5rem;
					a {
						padding: 1.5rem 0; height: auto; line-height: 1.5; 
						&:before {
							width:3px !important; height: 0; left: -12px;
							top: 14px; bottom: unset;    
						}
						&:hover, &.active {
							&:before {height:20px;}
						}
					}
					&:last-child{
						margin-bottom:0; border-bottom: 0;
					}
				}				

				
			}
			&:last-child{
				margin-right: 0rem;
			}
			&:first-child {
				a {
					&:before {display:none;} 
				}
			}

			&:hover{
				> ul{
					opacity: 1;
					visibility: visible;
					top: 100%;
				}
			}
		}
	}
	.search-head{
		.btn-search{
			display: inline-block;
			width: 2rem;
			height: 2rem;
			background: url('../images/material/icon-search.svg')no-repeat center;
			margin-bottom: -.4rem;
			cursor: pointer;
		}
		.drop-search{
			display: none;
			@include position(absolute, 100% 0 null null);

			form,input{margin-bottom: 0;}
			@include placeholder{color: $color-initial;text-transform: capitalize;} 
			input{
				font-size: 1.4rem;
				padding: .6rem 3.5rem .6rem .6rem; 
				color: $color-initial;
				background: transparent;
				text-transform: capitalize;
				&:active,&:focus{
					border-color: $color-initial;
				}
				&.go {
					position: absolute; width: 30px; height: 100%; content: ""; min-width: 10px; 
					cursor: pointer; padding: 0; 
					margin: 0; border:0; right: 0; top: 0; 
					background: url('../images/material/ico-search-sm.svg') no-repeat right center;  
					opacity: .7; 
					&:before {display:none;}  					
				}
			}			
		}
	}

	&.float{
		background: #1A1A1A;
		box-shadow: 0 .2rem 1rem 0 rgba(0,0,0,0.05);
		padding: 1rem 0 0;
		
		.nav {
			a {
				height: 40px; line-height: 40px;				 
			}
			> li > ul {
				margin-top: 0;
				> li {
					a {height: auto; line-height: 1.5;} 
				}
			}
		}
		.lang-head {
			a {line-height: 40px;}
		}
		.search-head{
			height: 40px;
			.btn-search {height: 100%; margin-bottom: 0;}
			.drop-search{
				input[type="text"]{
					background: #1A1A1A;
				}
				.go {background-position: center center;} 
			}
		}
	}
}

/* content
----------------------------------------------------------------------------------------------*/
.space-left{padding-left: 2.3rem;}
.box-video{
	position: relative;
	> img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
		@include position(relative, 0);
	}	
	.wrap-iframe {
		top: 0; left: 0; 
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        display: none; 
        z-index: 6; 
		iframe {
			width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;	
			z-index: 7;				
		} 
	}
	/* iframe{
		width: 100%;
		min-height: 38.5rem;
	} */
	.btn-play-video{
		width: 10rem;
		height: 9rem;
		margin: auto;
		text-align: center;
		z-index: 2;
		color: $color-initial;
		font-weight: 700;
		font-size: 1.2rem;
		text-transform: uppercase;
		letter-spacing: .2rem;
		cursor: pointer;
		@include position(absolute, 0);

		&:before{
			content:"";
			display: inline-block;
			width: 6rem;
			height: 6rem;
			background: url('../images/material/icon-play.svg')no-repeat center;
			margin-bottom: 1rem;
		}
		span{display: block;}
	}
}

.sosmed-box{
	a{
		margin-right: 2.5rem;
		&:last-child{margin-right: 0;}
	}
}

.banner{
	position: relative;
	figure{
		img{
			width: 100%;
		}
	}
	figcaption{
		width: 97rem;
		margin: 0 auto;
		text-align: center;
		color: $color-initial;
		@include position(absolute, null 0 4rem 0);

		&:before{
			content:"";
			width: .1rem;
			height: 5rem;
			margin: 0 auto 3rem;
			background: $color-initial;
			@include position(absolute, null 0 100% 0);
		}
	}
}

.banner-large{
	position: relative;

	p{
		font-size: 1.6rem;
		line-height: 2rem;
		font-weight: 300;
	}
	figure{
		position: relative;
		overflow: hidden;
		
		img{
			width: 100%;
			height: 100vh;
			object-fit: cover;
		}
	}
	figcaption{
		width: 97rem;
		margin: 0 auto;
		text-align: center;
		color: $color-initial;
		@include position(absolute, 50% 0 null 0);

		&:before{
			content:"";
			width: .1rem;
			height: 5rem;
			margin: 0 auto 3rem;
			background: $color-initial;
			@include position(absolute, null 0 100% 0);
		}
	}
}

.space-gen{
	padding: 8rem 0;

	&.no-padd-bottom{
		padding-bottom: 0;
	}
}

.bg-grey{
	background: #F8F8F8;
}

.side-content{
	display: flex;
	justify-content: space-between;

	.title-side{
		flex: 1 0 43%;
		max-width: 43%;
	}
	.content-side{
		flex: 1 0 50%;
		max-width: 50%;
	}

	&.middle-content{
		align-items: center;
	}
}

.tx{
	position: relative;
	.char-tx{
		font-size: 9rem;
		font-weight: 700;
		font-family: $trajan;
		color: #4D4D4D;
		opacity: .1;
		@include position(absolute, -4.7rem null null -2rem);
	}
}

.thumb-list{
	figure{
		margin-bottom: 2rem;
		overflow: hidden;

		img{
			width: 100%;
			@include transition(.2s all ease-out);
		}

		&:hover{
			img{
				transform: scale(1.05);
			}
		}
	}
	figcaption{
		h4{
			font-family: $trajan;
			color: #4D4D4D;
			margin-bottom: .4rem;
			line-height: 1.2;
		}
		span{
			display: block;
			font-size: 1.4rem;
			color: #7F7F7F;

			&.light{
				font-size: 1.2rem;
				font-weight: 700;
				color: #999999;
			}
		}
		p{
			color: #4D4D4D;
			line-height: 1.5;
			margin-bottom: 1rem;
		}
	}
	.column{cursor: pointer;}
	.thumb-popup-content{display: none;}

	&.row{
		margin-left: -1.5rem;
		width: calc(100% + 3.0rem);
		.column{
			padding: 0 1.5rem;
			margin-bottom: 4rem;
		}
	}
	&.committee-list {
		figcaption {			
			h4 {
				min-height: 24px; max-height: 70px; overflow: hidden;
			}
		}
	}
}

.special-popup{
	.thumb-popup-content{
		display: none;
	}
}

.thumb-side{
	.column{
		margin-bottom: 3rem;

		h4{
			color: #4D4D4D;
			margin-bottom: 1rem;
		}
		span{
			font-size: 1.2rem;
			font-weight: 700;
		}
	}
}

.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;}

.std-content{
	img{width: 100%;}
	a {
		text-decoration: underline;
		&:hover {text-decoration: none;}
	}
}
.table-std {
	overflow: auto; 
}
.wrap-iframe {
	top: 0; left: 0; margin-bottom: 20px; 
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
	iframe {
		width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;					
	}
}
.pagination{
	a{
		width: 4rem;
		height: 4rem;
		line-height: 4rem;
		color: #A6A9AA;
		font-weight: 700;
		font-size: 1.4rem;
		border: .1rem solid transparent;
		display: inline-block;
		text-align: center;

		&:hover{
			border-color: #A6A9AA;
		}
		&.active{
			border-color: #4D4D4D;
			color: #4D4D4D;
		}
	}
}

figure.shadow {
	@extend .shadow;
}

label.error {padding: 5px 0 0 0;color: red;}
.error {
	font-size: 12px; margin-bottom: 0;
}

.captcha {
	margin: 0 0 20px 0;  
}


/* footer
----------------------------------------------------------------------------------------------*/
footer{
	background: #1A1A1A;
	color: rgba(255,255,255,.5);
	padding: 11.8rem 0;
	position: relative;
	z-index: 1;

	a{color: rgba(255,255,255,.5);}
	p{font-size: 1.2rem;line-height: 1.6rem;margin-bottom: 2rem;}
	.wrapper{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	.logo-foot{
		display: block;
		margin-bottom: 4rem;
	}
	.sosmed-box{
		margin-bottom: 4.5rem;
		text-align: right;
	}
}
/* end footer
----------------------------------------------------------------------------------------------*/