@media all and (max-width: 740px) {
	.home-section {overflow: hidden;}
	.banner {
		p {font-size: 1.4rem;}
	}
	.banner-large {
		p {font-size: 1.4rem;} 
	}
	.slider-home{
		figure{
			min-height: 48rem;
		}
		figcaption{
			width: 100%;
			padding: 0 2rem;
			top: 42%; 
		}

		.slick-arrow{
			right: .5rem;

			&.slick-prev{
				right: 7.5rem;
			}
		}
	}
	.scroll-down{
		padding-top: 1rem;
		&:before{
			height: 7rem;
		}
	}
	.num-slider{
		font-size: 1.6rem;

		.num{
			font-size: 2.6rem;
			top: -.5rem;
		}
	}
	.box-slider-home{
		.num-slider{
			right: 3rem;
			bottom: 1.5rem;
		}
	}
	.quotes-home{
		min-height: 0;
		padding: 11rem 2rem;
	}
	.quality-home{
		height: auto;
		.left-quality-home{			
			.slider-quality-thumb{
				bottom: 4rem;
				left: 2rem;
			}
			.info-slide{
				bottom: 1.5rem;
				left: 2rem;
				right: 2rem;
			}
		}
		.right-quality-home{
			padding: 7rem 2rem 5.5rem;
		}
	}
	.slider-quality-large{	
		.slick-arrow {	display: none; }
		.list{
			figure {
				position: absolute; height: 105vh; 
			}
			figcaption{
				/* top: 5rem;
				left: 2rem;
				right: 2rem; */
				/*width: auto;*/
				position: relative;		
				width: 100%;
				padding: 5rem 2rem 12rem 2rem;	
				box-sizing: border-box;
				top: 0; left: 0;	
			}
		}
	}

	.slider-product{
		figure{			
			// min-height: 50rem;
			// height: 50rem;	
			 &:before {
				width:40rem; height: 40rem;		
				top: 50%; left: 50%; transform: translate(-50%,-50%) scale(1); 
				box-sizing: border-box; 
				margin-left: 0;
    			margin-top: 0;	
			} 
			&:after {
				width:30rem; height: 30rem;   
				top: 50%; left: 50%; transform: translate(-50%,-50%) scale(1); 
				box-sizing: border-box; 
				margin-left: 0;
    			margin-top: 0;
			}
			&.slick-current {				
				&:before {transform: translate(-50%,-50%) scale(1.11);}
				&:after {
					transform: translate(-50%,-50%) scale(1.2);  
				}  				
			}
		}
		.slick-slide {
			/*height: auto; */
			&.slick-current {
				figure {
					&:before {transform: translate(-50%,-50%) scale(1.11);}
					&:after {
						transform: translate(-50%,-50%) scale(1.2);  
					}  
				}
			}
		}
	}
	.product-home{
		.text-slider-product{
			width: 28rem;
			margin-top: 0;
			@include transform(translateY(-50%) scale(1)); 
			.list{
				margin: 0 0;
				box-sizing: border-box; padding: 0 30px;
				h6{margin-bottom: 2rem;}
				h3{margin-bottom: 1rem; font-size: 1.6rem;}
				p {line-height: 1.2; margin-bottom: 2rem;} 
				span {line-height: 1; display: block;}
				a {
					height: 4rem; line-height: 4rem; min-width: 14rem;
				}
			}
		}
		.indicator-slider-product{		
			width: 100%; 
			height: 30rem;
			margin-top: 0;
			@include transform(translateY(-50%) scale(1));
			&:before{
				width:30rem;
				height: 30rem;
				background: none; 
				border:1px solid #fff;
				opacity: .4; 
				border-radius: 50%; 
			}
			a{
				&:first-child{
					left: auto;
					right: 0;

					&:before{
						margin: 1.9rem 0 0;
						left: 1.6rem;
					}
				}
				&:nth-child(2){
					top: 100%;
					transform: translateY(0);

					&:before{
						top: auto;
						bottom: 100%;
					    left: 2.7rem;
					    right: 0;
					    margin: 0 0 2.7rem;
					}
					&:after {left: 6rem;top: -20px;}
				}
				&:nth-child(3){
					right: auto;

					&:before{
					    left: auto;
					    right: 2.7rem;
					    margin: 0 0 2.7rem;
					}
				}
				&:last-child{
					top: auto;
					bottom: 100%;
					transform: translateY(0);

					&:before{
						top: 100%;
						right: 1.6rem;
						margin-top: 1.9rem;
					}
					&:after {
						top: 100%;
					    right: 50px;
					    margin-top: 10px;
					}
				}
			}
			.box-circle-indicator {
				width: 30rem; height: 30rem; 
				display: none;
				span {
					width: 15rem; height: 15rem; 
					svg {height: 100%;}					
				}
			}
			.box-list-indicator {
				width: 32rem; height: 30rem; position: absolute;
				margin: auto; top: 0; right: 0; bottom: 0; left: 0; 
			}
			.btn-reload-slider {
				bottom: 2rem;
			}
		}
	}
}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 823px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
  	.scroll-down {
  		bottom: 1.5rem;
		&:before {
			height:2rem; display: none; 
		}
	}
	.slider-home {
		figcaption {
			top: 35%; 
		}
	}
	.banner {
		figure {
			height: 100vh; 
		}
	}
	.banner-large {
		figure {
			height: 100vh; 
		}
		figcaption {
			&:before {display:none;} 
		}
	}
	// .slider-quality-large {
	// 	.list {
	// 		figure {
	// 			height: 100%;
	// 		}
	// 	}
	// }
}
