@media all and (max-width: 740px) {
	header{
		padding: 2rem 2rem 0;
		.logo{
			img{width: 5.8rem;}
		}
		.burger-nav{
			top: 1rem;
			width: 2rem;

			span{
				&:nth-child(2){
					top: .8rem;
				}
				&:last-child{
					top: 1.6rem;
				}
			}
		}
		.nav-right{
			width: 100%;
			max-height: 100vh;
			overflow-y: auto;
			padding-bottom: 3rem;
			&:after {
				width:100%; display: none; 
			}
		}
		.search-head{
			position: relative;
			right: auto;
			left: auto;
			bottom: auto;
			margin-top: 3rem;
			width: 100%;
		}

		&.float{
			padding: 1rem 2rem .8rem;
		}
	}

	h1, h2, h3, h4, h5, h6{
		margin-bottom: 1.5rem;
	}
	h2{font-size: 3rem;}
	h3{font-size: 2rem;}
	h4{font-size: 1.6rem;}

	.space-left{padding-left: 0;}
	.space-gen{padding: 4.6rem 0;}
	.box-video{
		iframe{height: 22rem;min-height: 0;}
	}
	.banner{
		figure{
			img{
				min-height: 30rem;
				object-fit: cover;
			}
		}
		figcaption{
			bottom: 3rem;
		}
	}
	.banner-large{
		figure{
			img{
				min-height: 50rem;
			}
		}
	}
	.mid-desktop{
		.row{
			flex-wrap: wrap;

			.column{
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}
	.mid-desktop, .wrapper-middle, .wrapper-small{
		width: 100%;
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.side-content{
		width: 100%;
	}
	.thumb-side{
		.row,&.row{
			margin-bottom: 3rem;

			.column{
				flex-basis: 100%;
				max-width: 100%;
				margin-bottom: 1rem;

				img{width: 100%;}
			}
		}
	}
	.thumb-list{
		&.row{
			.column{
				flex-basis: 100%;
				max-width: 100%;
			}
		}
		&.committee-list {
			.column {}
			figcaption {			
				h4 {
					min-height: 10px; max-height: none; height: auto;
				}
			}
		}	
	}
	.row.row-intro {
		.column {
			figure {margin-bottom: 30px;}
			&.column-41 {flex: 0 0 100%; max-width: 100%;}
			&.column-51 {flex: 0 0 100%; max-width: 100%;}
		}
	}
	form {
		.row {
			.column {
				.button {
					width: 100%; 
				}
			}
		}
	}
	.captcha {
		width: 220px;
		.g-recaptcha {
			width: 100% !important;
			> div {
				margin: auto;
  				width: 100% !important;
  				> div {
  					transform: scale(0.9);
  					margin-left: 0px;
  					position: relative;
  					left: -10px;
  				}
  			}
		}
	}

	footer{
		padding-bottom: 5rem;

		.wrapper{
			flex-wrap: wrap;
			text-align: center;
			justify-content: center;
		}
		.sosmed-box{
			text-align: center;
		}
	}
}

