@media all and (max-width: 1300px) {
	.news-section{
		.wrapper-middle{
			width: 57.2rem;
		}
		.row{
			flex-wrap: wrap;
			
			.column{
				&.column-28,&.column-59{
					flex-basis: 100%;
					max-width: 100%;
				}
			}
		}
	}
}