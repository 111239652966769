.search-list{
	margin-bottom: 6rem;
	.list{
		display: block;
		padding: 3rem 6rem 3rem 0;
		border-top: .1rem solid #EAEAEA;
		position: relative;

		&:before{
			content:"";
			width: 3rem;
			height: 2rem;
			background: url('../images/material/arrow-right.svg')no-repeat center right;
			@include position(absolute, null 0 4.5rem null);
		}
		h4{
			font-family:$trajan;
			margin-bottom: 1rem;
		}
		p{margin-bottom: 0;}

		&:last-child{
			border-bottom: .1rem solid #EAEAEA;
		}
	}
}

.full-screen{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	h1,h2{
		font-size: 8rem;
		font-weight: 700;
		margin-bottom: 0;
	}
	h4{
		margin-bottom: 1rem;
	}
	figure{
		z-index: -1;
		@include position(absolute, 0);
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.inner-section{
		text-align: center;
		color: $color-initial;
	}

	&.error-page{
		h1,h2{
			font-size: 18rem;
			margin-bottom: -3rem;
		}
	}
}