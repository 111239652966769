.slider-home{
	position: relative;
	z-index: 1;

	figure{
		height: 100vh;
		overflow: hidden;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	figcaption{
		margin: 0 auto;
		width: 50rem;
		text-align: center;
		color: $color-initial;
		@include transform(translateY(-50%));
		@include position(absolute, 50% 0 null 0);

		img{
			margin-bottom: 1rem;
			display: inline-block;
		}
		h5{font-weight: 300;}
	}
	.list{
		position: relative;
	}
	.slick-arrow{
		z-index: 2;
		width: 2rem;
		height: 2rem;
		min-width: 0;
		font-size: 0;
		color: transparent;
		padding: 0;
		margin: 0;
		border: none;
		background: url('../images/material/arrow-slider.svg')no-repeat center;
		@include position(absolute, null 3.6rem 3.3rem null);

		&.slick-prev{
			right: 18rem;
		}
		&.slick-next{
			@include transform(rotate(180deg));
		}
	}
}

.num-slider{
	font-size: 2.5rem;
	font-weight: 700;
	color: $color-initial;
	font-family: $trajan;

	.num{
		font-size: 3.9rem;
		top: -1rem;
		display: inline-block;
		position: relative;
	}
}

.scroll-down{
	width: 10rem;
	margin: 0 auto;
	color: #C7CBD6;
	font-size: 1rem;
	text-transform: uppercase;
	position: relative;
	padding-top: 3rem;
	cursor: pointer;
	@include position(absolute, null 0 3rem 0);

	&:before{
		content:"";
		width: .1rem;
		height: 17rem;
		background: rgba(255,255,255,.5);
		margin: 0 auto;
		@include position(absolute, null 0 100% 0);
	}
}

.box-slider-home{
	position: relative;
	height: 100vh;
	z-index: 1;

	.inner-slider-home{
		z-index: -1;
		@include position(fixed, 0);
	}
	.num-slider, .scroll-down{
		z-index: 2;
	}
	.num-slider{
		z-index: 2;
		@include position(absolute, null 9rem 1rem null);
	}
}

.home-section{
	position: relative;
	background: $color-initial;
	z-index:2;
}

.about-home{
	padding: 5rem 0;
	position: relative;
	display: flex;
	align-items: center;
	height: calc(100vh - 10rem);
	min-height: 52rem;

	&:after{
		content:"";
		background: #242424;
		width: 33%;
		z-index: -1;
		@include position(absolute, 0 0 0 null);
	}
	.wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left-about-home{
			width: 35%;
			.space-left {
				padding-left: 0; 
			}
		}
		.right-about-home{
			width: 50%;
		}
	}
}

.slider-product{
	position: relative;		
	figure{
		/*height: calc(100vh - 9.6rem);*/
		height: calc(100vh - 9rem);
		min-height: 60rem;
		position: relative;

		&:before{
			content:"";
			width: 112rem;
			height: 112rem;
			border-radius: 50%;
			border: .1rem solid rgba(255,255,255,.1);
			display: block;
			margin: auto;
			transform: scale(.41);
			transition: .5s all ease-out;
			transition-delay: .2s;
			opacity: 0;
			@include position(absolute, 0);
		}
		&:after{	
			content:"";
			width: 92.5rem;
			height: 92.5rem;
			border-radius: 50%;
			border: .1rem solid rgba(255,255,255,.4);
			display: block;
			margin: auto;
			transform: scale(.48);
			transition: .5s all ease-out;
			transition-delay: .2s;
			opacity: 0;
			@include position(absolute, 0);
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&.slick-current{			
			&:before,&:after{
				transform:scale(1);
				transition-delay: .6s;
				opacity: 1;
			}
			&:after{
				transform:scale(1.11);
			}			
		}
	}
	.slick-slide {
		figure {}
		&.slick-current{
			figure {
				&:before,&:after{
					transform:scale(1);
					transition-delay: .6s;
					opacity: 1;
				}
				&:after{
					transform:scale(1.11);
				}
			}
		}
	}
}

.product-home{
	position: relative;	
	.text-slider-product{
		width: 38rem;
		margin: 0 auto 0 auto;
		z-index: 3;
		@include position(absolute, 50% 0 null);
		@include transform(translateY(-50%));

		.list{
			text-align: center;
			color: $color-initial;

			h3, h6{color: $color-initial;}
			h6{margin-bottom: 3rem;}
			h3{margin-bottom: 2rem;}
			p{margin-bottom: 3rem;}
			span{font-size: 1rem;color: rgba(255,255,255,.6);}
		}
	}

	.indicator-slider-product{
		width: 68.8rem;
		height: 48.7rem;
		margin:0 auto 0 auto;
		z-index: 2;
		@include position(absolute, 50% 0 0 0);
		@include transform(translateY(-50%));

		&:before{
			content:"";
			width: 45rem;
			height: 45rem;
			margin: auto;
			background: url('../images/material/bg-circle-line.svg')no-repeat center;
			background-size: 100%;
			@include position(absolute, 0);
		}
		a{
			position: absolute;
			display: inline-block;
			width: 10rem;
			text-align: center;
			color: rgba(255,255,255,.6);
			font-size: 1.4rem;
			line-height: 1.6rem;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: .1rem;

			&:before{
				content:"";
				width: 1.3rem;
				height: 1.3rem;
				display: block;
				border-radius: 50%;
				border: .1rem solid rgba(255,255,255,.6);
				position: absolute;
				transform: scale(.8);
				transition: .2s all ease-out;
				transition-delay: 1s;
			}
			&:after{
				content:"";
				width: .5rem;
				height: .5rem;
				display: block;
				border-radius: 50%;
				position: absolute;
				background: $color-initial;
				opacity: 0;
				transition: .2s all ease-out;
				transition-delay: 0s;
			}
			&:first-child{
				bottom: 100%;left: 0;right: 0;margin: 0 auto;
				&:before{top: 100%;left: 0;right: 0;margin: 1.3rem auto 0;}
				&:after{top: 100%;left: 0;right: 0;margin: 1.7rem auto 0;}
			}
			&:nth-child(2){
				right: 0;top: 50%;transform: translateY(-50%);text-align: left;
				&:before{top: 50%;left: -2.7rem;margin-top: -.7rem;}
				&:after{top: 50%;left: -2.3rem;margin-top: -.3rem;}
			}
			&:nth-child(3){
				top: 100%;left: 0;right: 0;margin: 0 auto;
				&:before{bottom: 100%;left: 0;right: 0;margin:0 auto 1.2rem;}
				&:after{bottom: 100%;left: 0;right: 0;margin:0 auto 1.6rem;}
			}
			&:last-child{
				left: 0;top: 50%;transform: translateY(-50%);text-align: right;
				&:before{top: 50%;right: -2.7rem;margin-top: -.7rem;}
				&:after{top: 50%;right: -2.3rem;margin-top: -.3rem;}
			}

			&.bordered, &.active{
				&:before{
					border: .3rem solid $color-initial;
					width: 1.3rem;
					height: 1.3rem;
					transform: scale(1);
				}
			}

			&.active{
				color: $color-initial;
				font-weight: 700;
				&:after{opacity: 1;transition-delay: 1s;}
			}
		}
		.box-circle-indicator{
			width: 45.3rem;
			height: 45.2rem;
			margin: auto;
			@include position(absolute, 0);

			span{
				display: block;
				width: 22.2rem;
				height: 22.2rem;
				@include position(absolute, 0 null null 0);

				svg{
					width: 100%;
					path{
						stroke-dasharray: 1070;
						stroke-dashoffset: 1070;
					}
				}
				&:first-child{
					right: 0;
					left: auto;
				}
				&:nth-child(2){
					transform: rotate(90deg);
					bottom: 0;
					right: 0;
					top: auto;
					left: auto;
				}
				&:nth-child(3){
					transform: scale(-1, -1);
					bottom: 0;
					top: auto;
				}
				&:last-child{
					transform: rotate(270deg);
				}

				&.show{
					svg{
						path{
							animation: linecircle 1s linear forwards;
						}
					}
				}

				&.back{
					svg{
						path{
							animation: linecircle 2s linear reverse;
						}
					}
				}
			}
		}
		.btn-reload-slider{
			width: 2.5rem;
			height: 2.5rem;
			background: url('../images/material/icon-reload.svg')no-repeat center;
			margin: 0 auto;
			cursor: pointer;
			transition: .2s all ease-out;
			@include position(absolute, null 0 4rem);

			&:hover{
				transform: rotate(180deg);
			}
		}
	}
}

@keyframes linecircle {
  from {
    stroke-dashoffset: 1070;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.quotes-home{
	min-height: 40rem;
	background: #F5F5F5;
	padding: 10rem;
	display: flex;
	align-items: center;
	text-align: center;

	.wrapper{max-width: 97rem;}
}

.quality-home{
	display: flex;
	height: calc(100vh - 9.0rem);    
	.left-quality-home,.right-quality-home{
		flex: 1 0 50%;
		max-width: 50%;
	}
	.left-quality-home{
		position: relative;

		.slider-quality-thumb{
			/*@include position(absolute, null 3.8rem 4.5rem);*/
			@include position(absolute, null 8rem 4.5rem);
		}
		.info-slide{
			color: $color-initial;
			font-size: 1rem;
			@include position(absolute, null 8rem 2.1rem);
		}
	}
	.right-quality-home{
		padding-left: 11.5rem;
		display: flex;
		align-items: center;
		.inner{
			max-width: 40rem;
			width: 100%;
		}
	}
}

.slider-quality-large{
	.slick-slide {line-height: 0;}
	.list{
		position: relative; height: 100%;
		figure{
			height: calc(100vh - 9.0rem); position: relative;
			&:before { background: rgba(0,0,0,.3); content: ''; height: 100%; position: absolute; width: 100%; }
			img{width: 100%; height: 100%; object-fit: cover; object-position: center;}  
		}
		figcaption{
			color: $color-initial;
			width: 43rem;
			line-height: normal; 
			@include position(absolute, 4.9rem null null 3.7rem);

			h3{
				color: $color-initial;
				margin-bottom: 1.5rem;
			}
			p{
				line-height: 2rem;
			}
		}
	}
	.slick-arrow { position: absolute; 
		/*top: 60%; z-index: 9; @include transform(translateY(-50%)); */
		width: 2rem;
		height: 6rem;
		padding: 0;
		border: none;
		min-width: 0;
		background: url('../images/material/arrow-light-slider.svg')no-repeat center;
		background-size: 100%;
		font-size: 0;
		color: transparent;
		z-index: 1;
		margin: auto 0;
		bottom: 53px;
		&:before{display: none;}
		&.slick-prev{
			left: 4rem;
		}
		&.slick-next{
			right: 4rem;
			transform: rotate(180deg);
		}
	}
}

.slider-quality-thumb{
	display: flex;
		align-items: flex-end;
	.slick-slide{
		margin:0 .25rem;		
		img{
			width: 8.4rem;
			transition: all .2s ease-out; 
		}

		 &.slick-current{
		 	transition: all .2s ease-out; 
			img{
				width: 10.9rem;
			}
		} 
	}
	.slick-track{
		display: flex;
		align-items: flex-end;
		&.custom {
			transform: translate3d(0px, 0px, 0px)!important;
		}
	}
}