@media all and (max-width: 740px) {
	.full-screen{
		h1, h2{
			font-size: 3rem;
		}
		.inner-section{
			padding: 0 2rem;
		}
		&.error-page{
			h1, h2{
				font-size: 8rem;
				margin-bottom: 0rem;
			}
		}
	}
}