
// Grid
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// .container is main centered wrapper with a max width of 112.0rem (1120px)
.container {
	margin: 0 auto;
	max-width: 112.0rem;
	padding: 0 2.0rem;
	position: relative;
	width: 100%;
}

.wrapper{
    margin: 0 auto;
    max-width: 117rem;
    width: 100%;
    position: relative;
}

.wrapper-middle{
    margin: 0 auto;
    max-width: 97rem;
    width: 100%;
    position: relative;
}

.wrapper-small{
    margin: 0 auto;
    max-width: 77rem;
    width: 100%;
    position: relative;
}

// Using flexbox for the grid, inspired by Philip Walton:
// http://philipwalton.github.io/solved-by-flexbox/demos/grids/
// By default each .column within a .row will evenly take up
// available width, and the height of each .column with take
// up the height of the tallest .column in the same .row
.row {
	display: flex;
	flex-direction: column;
	padding: 0;
	width: 100%;

	&.row-no-padding {
		padding: 0;

		&> .column {
			padding: 0;
		}
	}

	&.row-wrap {
		flex-wrap: wrap;
	}

    &.row-space-between {
        justify-content: space-between;
    }

    &.row-pos-right {
        justify-content: flex-end;
    }

	// Vertically Align Columns
	// .row-* vertically aligns every .col in the .row
	&.row-top {
		align-items: flex-start;
	}

	&.row-bottom {
		align-items: flex-end;
	}

	&.row-center {
		align-items: center;
	}

	&.row-stretch {
		align-items: stretch;
	}

	&.row-baseline {
		align-items: baseline;
	}

	.column {
		display: block;
		// IE 11 required specifying the flex-basis otherwise it breaks mobile
		flex: 1 1 auto;
		margin-left: 0;
		max-width: 100%;
		width: 100%;

        // Column Align Text
        &.column-left{
            text-align: left;
        }
        &.column-right{
            text-align: right;
        }
        &.column-center{
            text-align: center;
        }

		// Column Offsets
        &.column-offset-1 {
            margin-left: 1%;
        }

        &.column-offset-2 {
            margin-left: 2%;
        }

        &.column-offset-3 {
            margin-left: 3%;
        }

        &.column-offset-4 {
            margin-left: 4%;
        }

        &.column-offset-5 {
            margin-left: 5%;
        }

        &.column-offset-6 {
            margin-left: 6%;
        }

        &.column-offset-7 {
            margin-left: 7%;
        }

        &.column-offset-8 {
            margin-left: 8%;
        }

        &.column-offset-9 {
            margin-left: 9%;
        }

        &.column-offset-10 {
            margin-left: 10%;
        }

        &.column-offset-11 {
            margin-left: 11%;
        }

        &.column-offset-12 {
            margin-left: 12%;
        }

        &.column-offset-13 {
            margin-left: 13%;
        }

        &.column-offset-14 {
            margin-left: 14%;
        }

        &.column-offset-15 {
            margin-left: 15%;
        }

        &.column-offset-16 {
            margin-left: 16%;
        }

        &.column-offset-17 {
            margin-left: 17%;
        }

        &.column-offset-18 {
            margin-left: 18%;
        }

        &.column-offset-19 {
            margin-left: 19%;
        }

        &.column-offset-20 {
            margin-left: 20%;
        }

        &.column-offset-21 {
            margin-left: 21%;
        }

        &.column-offset-22 {
            margin-left: 22%;
        }

        &.column-offset-23 {
            margin-left: 23%;
        }

        &.column-offset-24 {
            margin-left: 24%;
        }

        &.column-offset-25 {
            margin-left: 25%;
        }

        &.column-offset-26 {
            margin-left: 26%;
        }

        &.column-offset-27 {
            margin-left: 27%;
        }

        &.column-offset-28 {
            margin-left: 28%;
        }

        &.column-offset-29 {
            margin-left: 29%;
        }

        &.column-offset-30 {
            margin-left: 30%;
        }

        &.column-offset-31 {
            margin-left: 31%;
        }

        &.column-offset-32 {
            margin-left: 32%;
        }

        &.column-offset-33 {
            margin-left: 33%;
        }

        &.column-offset-34 {
            margin-left: 34%;
        }

        &.column-offset-35 {
            margin-left: 35%;
        }

        &.column-offset-36 {
            margin-left: 36%;
        }

        &.column-offset-37 {
            margin-left: 37%;
        }

        &.column-offset-38 {
            margin-left: 38%;
        }

        &.column-offset-39 {
            margin-left: 39%;
        }

        &.column-offset-40 {
            margin-left: 40%;
        }

        &.column-offset-41 {
            margin-left: 41%;
        }

        &.column-offset-42 {
            margin-left: 42%;
        }

        &.column-offset-43 {
            margin-left: 43%;
        }

        &.column-offset-44 {
            margin-left: 44%;
        }

        &.column-offset-45 {
            margin-left: 45%;
        }

        &.column-offset-46 {
            margin-left: 46%;
        }

        &.column-offset-47 {
            margin-left: 47%;
        }

        &.column-offset-48 {
            margin-left: 48%;
        }

        &.column-offset-49 {
            margin-left: 49%;
        }

        &.column-offset-50 {
            margin-left: 50%;
        }

        &.column-offset-51 {
            margin-left: 51%;
        }

        &.column-offset-52 {
            margin-left: 52%;
        }

        &.column-offset-53 {
            margin-left: 53%;
        }

        &.column-offset-54 {
            margin-left: 54%;
        }

        &.column-offset-55 {
            margin-left: 55%;
        }

        &.column-offset-56 {
            margin-left: 56%;
        }

        &.column-offset-57 {
            margin-left: 57%;
        }

        &.column-offset-58 {
            margin-left: 58%;
        }

        &.column-offset-59 {
            margin-left: 59%;
        }

        &.column-offset-60 {
            margin-left: 60%;
        }

        &.column-offset-61 {
            margin-left: 61%;
        }

        &.column-offset-62 {
            margin-left: 62%;
        }

        &.column-offset-63 {
            margin-left: 63%;
        }

        &.column-offset-64 {
            margin-left: 64%;
        }

        &.column-offset-65 {
            margin-left: 65%;
        }

        &.column-offset-66 {
            margin-left: 66%;
        }

        &.column-offset-67 {
            margin-left: 67%;
        }

        &.column-offset-68 {
            margin-left: 68%;
        }

        &.column-offset-69 {
            margin-left: 69%;
        }

        &.column-offset-70 {
            margin-left: 70%;
        }

        &.column-offset-71 {
            margin-left: 71%;
        }

        &.column-offset-72 {
            margin-left: 72%;
        }

        &.column-offset-73 {
            margin-left: 73%;
        }

        &.column-offset-74 {
            margin-left: 74%;
        }

        &.column-offset-75 {
            margin-left: 75%;
        }

        &.column-offset-76 {
            margin-left: 76%;
        }

        &.column-offset-77 {
            margin-left: 77%;
        }

        &.column-offset-78 {
            margin-left: 78%;
        }

        &.column-offset-79 {
            margin-left: 79%;
        }

        &.column-offset-80 {
            margin-left: 80%;
        }

        &.column-offset-81 {
            margin-left: 81%;
        }

        &.column-offset-82 {
            margin-left: 82%;
        }

        &.column-offset-83 {
            margin-left: 83%;
        }

        &.column-offset-84 {
            margin-left: 84%;
        }

        &.column-offset-85 {
            margin-left: 85%;
        }

        &.column-offset-86 {
            margin-left: 86%;
        }

        &.column-offset-87 {
            margin-left: 87%;
        }

        &.column-offset-88 {
            margin-left: 88%;
        }

        &.column-offset-89 {
            margin-left: 89%;
        }

        &.column-offset-90 {
            margin-left: 90%;
        }

        &.column-offset-91 {
            margin-left: 91%;
        }

        &.column-offset-92 {
            margin-left: 92%;
        }

        &.column-offset-93 {
            margin-left: 93%;
        }

        &.column-offset-94 {
            margin-left: 94%;
        }

        &.column-offset-95 {
            margin-left: 95%;
        }

        &.column-offset-96 {
            margin-left: 96%;
        }

        &.column-offset-97 {
            margin-left: 97%;
        }

        &.column-offset-98 {
            margin-left: 98%;
        }

        &.column-offset-99 {
            margin-left: 99%;
        }

        &.column-offset-100 {
            margin-left: 100%;
        }

		&.column-offset-33,
		&.column-offset-34 {
			margin-left: 33.3333%;
		}
		&.column-offset-66,
		&.column-offset-67 {
			margin-left: 66.6666%;
		}


		// Explicit Column Percent Sizes
		// By default each grid column will evenly distribute
		// across the grid. However, you can specify individual
		// columns to take up a certain size of the available area
		&.column-1 {
            flex: 0 0 1%;
            max-width: 1%;
        }
        &.column-2 {
            flex: 0 0 2%;
            max-width: 2%;
        }
        &.column-3 {
            flex: 0 0 3%;
            max-width: 3%;
        }
        &.column-4 {
            flex: 0 0 4%;
            max-width: 4%;
        }
        &.column-5 {
            flex: 0 0 5%;
            max-width: 5%;
        }
        &.column-6 {
            flex: 0 0 6%;
            max-width: 6%;
        }
        &.column-7 {
            flex: 0 0 7%;
            max-width: 7%;
        }
        &.column-8 {
            flex: 0 0 8%;
            max-width: 8%;
        }
        &.column-9 {
            flex: 0 0 9%;
            max-width: 9%;
        }
        &.column-10 {
            flex: 0 0 10%;
            max-width: 10%;
        }
        &.column-11 {
            flex: 0 0 11%;
            max-width: 11%;
        }
        &.column-12 {
            flex: 0 0 12%;
            max-width: 12%;
        }
        &.column-13 {
            flex: 0 0 13%;
            max-width: 13%;
        }
        &.column-14 {
            flex: 0 0 14%;
            max-width: 14%;
        }
        &.column-15 {
            flex: 0 0 15%;
            max-width: 15%;
        }
        &.column-16 {
            flex: 0 0 16%;
            max-width: 16%;
        }
        &.column-17 {
            flex: 0 0 17%;
            max-width: 17%;
        }
        &.column-18 {
            flex: 0 0 18%;
            max-width: 18%;
        }
        &.column-19 {
            flex: 0 0 19%;
            max-width: 19%;
        }
        &.column-20 {
            flex: 0 0 20%;
            max-width: 20%;
        }
        &.column-21 {
            flex: 0 0 21%;
            max-width: 21%;
        }
        &.column-22 {
            flex: 0 0 22%;
            max-width: 22%;
        }
        &.column-23 {
            flex: 0 0 23%;
            max-width: 23%;
        }
        &.column-24 {
            flex: 0 0 24%;
            max-width: 24%;
        }
        &.column-25 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        &.column-26 {
            flex: 0 0 26%;
            max-width: 26%;
        }
        &.column-27 {
            flex: 0 0 27%;
            max-width: 27%;
        }
        &.column-28 {
            flex: 0 0 28%;
            max-width: 28%;
        }
        &.column-29 {
            flex: 0 0 29%;
            max-width: 29%;
        }
        &.column-30 {
            flex: 0 0 30%;
            max-width: 30%;
        }
        &.column-31 {
            flex: 0 0 31%;
            max-width: 31%;
        }
        &.column-32 {
            flex: 0 0 32%;
            max-width: 32%;
        }
        &.column-35 {
            flex: 0 0 35%;
            max-width: 35%;
        }
        &.column-36 {
            flex: 0 0 36%;
            max-width: 36%;
        }
        &.column-37 {
            flex: 0 0 37%;
            max-width: 37%;
        }
        &.column-38 {
            flex: 0 0 38%;
            max-width: 38%;
        }
        &.column-39 {
            flex: 0 0 39%;
            max-width: 39%;
        }
        &.column-40 {
            flex: 0 0 40%;
            max-width: 40%;
        }
        &.column-41 {
            flex: 0 0 41%;
            max-width: 41%;
        }
        &.column-42 {
            flex: 0 0 42%;
            max-width: 42%;
        }
        &.column-43 {
            flex: 0 0 43%;
            max-width: 43%;
        }
        &.column-44 {
            flex: 0 0 44%;
            max-width: 44%;
        }
        &.column-45 {
            flex: 0 0 45%;
            max-width: 45%;
        }
        &.column-46 {
            flex: 0 0 46%;
            max-width: 46%;
        }
        &.column-47 {
            flex: 0 0 47%;
            max-width: 47%;
        }
        &.column-48 {
            flex: 0 0 48%;
            max-width: 48%;
        }
        &.column-49 {
            flex: 0 0 49%;
            max-width: 49%;
        }
        &.column-50 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        &.column-51 {
            flex: 0 0 51%;
            max-width: 51%;
        }
        &.column-52 {
            flex: 0 0 52%;
            max-width: 52%;
        }
        &.column-53 {
            flex: 0 0 53%;
            max-width: 53%;
        }
        &.column-54 {
            flex: 0 0 54%;
            max-width: 54%;
        }
        &.column-55 {
            flex: 0 0 55%;
            max-width: 55%;
        }
        &.column-56 {
            flex: 0 0 56%;
            max-width: 56%;
        }
        &.column-57 {
            flex: 0 0 57%;
            max-width: 57%;
        }
        &.column-58 {
            flex: 0 0 58%;
            max-width: 58%;
        }
        &.column-59 {
            flex: 0 0 59%;
            max-width: 59%;
        }
        &.column-60 {
            flex: 0 0 60%;
            max-width: 60%;
        }
        &.column-61 {
            flex: 0 0 61%;
            max-width: 61%;
        }
        &.column-62 {
            flex: 0 0 62%;
            max-width: 62%;
        }
        &.column-63 {
            flex: 0 0 63%;
            max-width: 63%;
        }
        &.column-64 {
            flex: 0 0 64%;
            max-width: 64%;
        }
        &.column-65 {
            flex: 0 0 65%;
            max-width: 65%;
        }
        &.column-68 {
            flex: 0 0 68%;
            max-width: 68%;
        }
        &.column-69 {
            flex: 0 0 69%;
            max-width: 69%;
        }
        &.column-70 {
            flex: 0 0 70%;
            max-width: 70%;
        }
        &.column-71 {
            flex: 0 0 71%;
            max-width: 71%;
        }
        &.column-72 {
            flex: 0 0 72%;
            max-width: 72%;
        }
        &.column-73 {
            flex: 0 0 73%;
            max-width: 73%;
        }
        &.column-74 {
            flex: 0 0 74%;
            max-width: 74%;
        }
        &.column-75 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        &.column-76 {
            flex: 0 0 76%;
            max-width: 76%;
        }
        &.column-77 {
            flex: 0 0 77%;
            max-width: 77%;
        }
        &.column-78 {
            flex: 0 0 78%;
            max-width: 78%;
        }
        &.column-79 {
            flex: 0 0 79%;
            max-width: 79%;
        }
        &.column-80 {
            flex: 0 0 80%;
            max-width: 80%;
        }
        &.column-81 {
            flex: 0 0 81%;
            max-width: 81%;
        }
        &.column-82 {
            flex: 0 0 82%;
            max-width: 82%;
        }
        &.column-83 {
            flex: 0 0 83%;
            max-width: 83%;
        }
        &.column-84 {
            flex: 0 0 84%;
            max-width: 84%;
        }
        &.column-85 {
            flex: 0 0 85%;
            max-width: 85%;
        }
        &.column-86 {
            flex: 0 0 86%;
            max-width: 86%;
        }
        &.column-87 {
            flex: 0 0 87%;
            max-width: 87%;
        }
        &.column-88 {
            flex: 0 0 88%;
            max-width: 88%;
        }
        &.column-89 {
            flex: 0 0 89%;
            max-width: 89%;
        }
        &.column-90 {
            flex: 0 0 90%;
            max-width: 90%;
        }
        &.column-91 {
            flex: 0 0 91%;
            max-width: 91%;
        }
        &.column-92 {
            flex: 0 0 92%;
            max-width: 92%;
        }
        &.column-93 {
            flex: 0 0 93%;
            max-width: 93%;
        }
        &.column-94 {
            flex: 0 0 94%;
            max-width: 94%;
        }
        &.column-95 {
            flex: 0 0 95%;
            max-width: 95%;
        }
        &.column-96 {
            flex: 0 0 96%;
            max-width: 96%;
        }
        &.column-97 {
            flex: 0 0 97%;
            max-width: 97%;
        }
        &.column-98 {
            flex: 0 0 98%;
            max-width: 98%;
        }
        &.column-99 {
            flex: 0 0 99%;
            max-width: 99%;
        }
        &.column-100 {
            flex: 0 0 100%;
            max-width: 100%;
        }

		&.column-33,
		&.column-34 {
			flex: 0 0 33.3333%;
			max-width: 33.3333%;
		}

		&.column-66,
		&.column-67 {
			flex: 0 0 66.6666%;
			max-width: 66.6666%;
		}

		// .column-* vertically aligns an individual .column
		.column-top {
			align-self: flex-start;
		}

		.column-bottom {
			align-self: flex-end;
		}

		.column-center {
			align-self: center;
		}
	}
}

// Larger than mobile screen
@media (min-width: 40.0rem) { // Safari desktop has a bug using `rem`, but Safari mobile works

	.row {
		flex-direction: row;
		margin-left: -1.0rem;
		width: calc(100% + 2.0rem);

		.column {
			margin-bottom: inherit;
			padding: 0 1.0rem;
		}
	}
}
