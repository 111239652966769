.about-figure{
	h4{margin-bottom: 0;}
	.content-side{
		padding: 9rem 0 6.4rem;
		position: relative;
		
		&:before{
			content:"";
			border: 1rem solid #A6A9AA;
			width: 52.4rem;
			z-index: -1;
			@include position(absolute, 0 null 0 -15rem);
		}
		span{
			display: block;
			font-size: 1.4rem;
			color: #7F7F7F;
		}
	}
}

.awards-section{
	background: #242424;
	color: $color-initial;
	padding: 9.4rem 0;
	overflow: hidden;

	h6{margin-bottom: 1rem;}
	h3{color: #CCCCCC;}
	.num-slider{
		margin-right: 6rem;
	}
}

.slider-awards{
	position: relative;
	.list{
		border: .1rem solid #A6A9AA;
		padding: 1.5rem 1.5rem 3.6rem;
		margin: 0 3rem 6rem;
		position: relative;
		width: 40rem;
		cursor: pointer; 

		figcaption{
			background: #242424;
			text-align: center;
			color: #A6A9AA;
			font-family: $trajan;
			font-size: 1.6rem;
			margin-top: -2rem;
			padding: 0 2.5rem;
			@include position(absolute, 100% 3rem null);
		}
	}
	.slick-list{
		margin-right: -35rem;
	}
	.slick-arrow{
		font-size: 0;
		color: transparent;
		padding: 0;
		border:none;
		min-width: 0;
		width: 2rem;
		height: 2rem;
		line-height: 1;
		background: url('../images/material/arrow-slider.svg')no-repeat center;
		z-index: 1;
		@include position(absolute, -10rem 0 null null);

		&.slick-prev{
			right: 16rem;
		}
		&.slick-next{
			transform: rotate(180deg);
		}
	}
}

.milestone-side{
	position: relative;
	padding: 3rem 0 15rem;

	&:before{
		content:"";
		width: .1rem;
		margin: 0 auto;
		background: #ECEDED;
		@include position(absolute, 0);
	}
	.list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15.8rem;

		h3{
			font-size: 3.5rem;
			margin-bottom: 0;
		}
		h4{
			font-family: $helvetica;
			color: #7F7F7F;
		}
		figure, figcaption{
			flex: 1 0 44%;
			max-width: 44%;
		}
		figure{
			border: .1rem solid rgba(166,169,170,.2);
			padding: 1.7rem;

			img{
				width: 100%;
				box-shadow: .5rem 1.5rem 3rem 0 rgba(0,0,0,0.16);
			}
		}
		figcaption{
			position: relative;

			&:before{
				content:"";
				width: 3rem;
				height: 3rem;
				background: $color-initial;
				border-radius: 50%;
				border: .1rem solid #ECEDED;
				@include position(absolute, 2rem null null -7.3rem);
			}
			&:after{
				content:"";
				width: 1.2rem;
				height: 1.2rem;
				background: #4D4D4D;
				border-radius: 50%;
				@include position(absolute, 2.9rem null null -6.4rem);
			}
		}

		&:nth-child(even){
			figure{order: 2;}
			figcaption{
				order: 1;
				text-align: right;

				&:before{
					left: auto;
					right: -7.3rem;
				}
				&:after{
					left: auto;
					right: -6.4rem;
				}
			}
		}
	}
}

.box-values{
	.list{
		display: flex;
		justify-content: space-between;
		border-bottom: .1rem solid rgba(166,169,170,.2);
		padding-bottom: 4rem;
		margin-bottom: 4rem;

		h4{
			font-size: 2.4rem;
			font-family: $trajan;
			margin-bottom: 1rem;
		}
		p{margin-bottom: 0;}
		figure{
			flex: 1 0 7rem;
			max-width: 7rem;

			span{
				font-size: 1.2rem;
				font-weight: 700;
				color: #A6A9AA;
			}
		}
		figcaption{
			flex: 1 0 48rem;
			max-width: 48rem;
		}

		&:last-child{
			border-bottom: none;
		}
	}
}

.side-lg{
	display: flex;
	justify-content: space-between;
	padding: 4rem;
	position: relative;
	z-index: 1;

	&:before{
		content:"";
		width: 19rem;
		background: #242424;
		z-index: -1;
		@include position(absolute, 0 null 0 0);
	}

	h4{
		font-family: $trajan;
		color: #4D4D4D;
		margin-bottom: 0;
	}
	p{
		font-size: 1.2rem;
		line-height: 2rem;
		margin-bottom: 1rem;
	}
	figure{
		flex: 1 0 40%;
		max-width: 40%;
	}
	figcaption{
		flex: 1 0 52%;
		max-width: 52%;
	}
}

.slider-awards-large{
	position: relative;
	
	figcaption{
		padding: 2.3rem 7rem;
		min-height: 50px;
		h5{
			font-size: 1.6rem;
			color: #4D4D4D;
			margin-bottom: .5rem;
		}
		span{
			font-weight: 700;
			font-size: 1.2rem;
			display: block;
		}
	}

	.slick-arrow{
		width: 4rem;
		height: 4rem;
		padding: 0;
		border: none;
		min-width: 0;
		background: #242424 url('../images/material/arrow-light-slider.svg')no-repeat center;
		font-size: 0;
		color: transparent;
		z-index: 1;
		@include position(absolute, null null 1.0rem null);

		&:before{display: none;}
		&.slick-prev{
			left: 0;
		}
		&.slick-next{
			right: 0;
			transform: rotate(180deg);
		}
	}
}