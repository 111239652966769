@media all and (max-width: 740px) {
	.about-home{
		padding: 5.5rem 2rem 0;

		&:after{display:none;}
		.wrapper{
			.left-about-home{
				padding-right: 0;
			}
			.right-about-home{
				position: relative;
				padding: 7rem 0;

				&:after{
					content: "";
				    background: #242424;
				    width: 12rem;
				    z-index: -1;
				    position: absolute;
				    top: 0;
				    right: -2rem;
				    bottom: 0;
				}
			}
		}
	}
	.about-figure{
		.side-content{
			padding: 0 2rem;

			.content-side,.title-side{
				flex-basis: 100%;
				max-width: 100%;
			}
			.content-side{
				padding-top: 4.4rem;
				padding-bottom: 4rem;
				margin-bottom: 3rem;

				&:before{
					left: -3rem;
					width: calc(100% - 2rem);
				}
			}
			.title-side{margin-bottom: 0;}
		}
	}
	.committee-list{
		.column{
			&.column-33{
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}
	.awards-section{
		padding: 4rem 0 2rem 0;
		.wrapper-middle{
			width: 100%;
			padding: 0 2rem 6rem 2rem;
		}
		.num-slider{margin-right: 4rem; text-align: center;}
		.row{
			flex-wrap: wrap;

			.column{
				flex-basis: 100%;
				max-width: 100%;
				&.column-right {
					position: absolute; bottom: 0; 
				}
			}
		}
	}
	.slider-awards{
		.list{
			width: auto;
			margin: 0;
		}
		.slick-list{margin-right: 0;}
		.slick-arrow{
			/*top: -6rem;*/
			top: 100%; left: 50%; margin-top: 20px;
			&.slick-prev{
				right: 10rem; margin-left: -60px;
			}
			&.slick-next {
				margin-left: 30px;				
			}			
		}		
	}
	.milestone-side{
		padding-top: 0; padding-bottom: 0; margin-bottom: 70px;
		&:before{
			left: 1.5rem;
		}
		.list{
			padding-left: 5rem;
			margin-bottom: 4rem;

			&:before{left: 0;top: 3rem;}
			&:after{left: .9rem;top: 3.9rem}

			h4 {font-weight: bold;} 
			&:last-child {
				figcaption {
					p {margin-bottom: 0;}
				}
			}
		}
	}
	.box-values {
		.list {
			figure {
				text-align: center; 
			}
		}
	}
	.side-lg{
		flex-wrap: wrap;
		padding: 0 0 3rem;

		&:before{display: none;}
		figure,figcaption{
			flex-basis: 100%;
			max-width: 100%;
		}
		figure{
			margin-bottom: 2rem;
			img {
				width: 100%; 
			}
		}
		figcaption{
			padding: 0 2rem;
		}
	}
}