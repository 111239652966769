@media all and (max-width: 740px) {
	.news-section{
		.wrapper-middle{
			width: 100%;
		}
	}
	.public-list{
		h4{font-size: 1.6rem;}
		a{padding: 2rem 3rem 2rem 0;}
	}
}