.job-list{
	.list{
		display: flex;
		justify-content: space-between;
		border-top: .1rem solid #EAEAEA;
		padding: 4rem 9rem 4rem 2rem;
		font-size: 1.4rem;
		color: #7F7F7F;
		position: relative;
		align-items: center; 
		&:before{
			content:"";
			width: 2.5rem;
			background: url('../images/material/arrow-right.svg')no-repeat center;
			@include position(absolute, 0 0 0 null);
		}
		.left-list {
			width: 300px;
		}
		h4{
			margin-bottom: 0;
			font-family: $trajan;
			font-weight: 700;
			color: #4D4D4D;
		}
		&:last-child{
			border-bottom: .1rem solid #EAEAEA;
		}
	}
}

.detail-job{
	.list{
		padding:4rem 0 4rem 10rem;
		position: relative;
		border-bottom: .1rem solid rgba(166,169,170,.2);

		h4{
			font-family: $trajan;
			font-weight: 700;
			font-size: 2.4rem;
		}
		.number{
			font-weight: 700;
			font-size: 1.2rem;
			color: #A6A9AA;
			letter-spacing: .2rem;
			@include position(absolute, 5rem null null 0);
		}

		&:last-child{
			border-bottom: none;
		}
	}
}