/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama
==============================================================================================*/
@import "helper/sass-lib";
@import "helper/flexbox";

/* milligram
----------------------------------------------------------------------------------------------*/
@import "milligram/milligram";

/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general";
@import "pages/home";
@import "pages/about";
@import "pages/popup";
@import "pages/business";
@import "pages/sustainability";
@import "pages/news";
@import "pages/career";
@import "pages/misc";

/* pages responsive desktop
----------------------------------------------------------------------------------------------*/
@import "pages_desktop/general";
@import "pages_desktop/home";
@import "pages_desktop/misc";

/* pages responsive tablet
----------------------------------------------------------------------------------------------*/
@import "pages_tablet/general";
@import "pages_tablet/home";
@import "pages_tablet/about";
@import "pages_tablet/business";
@import "pages_tablet/sustainability";
@import "pages_tablet/news";
@import "pages_tablet/career";
@import "pages_tablet/popup";

/* pages responsive mobile
----------------------------------------------------------------------------------------------*/
@import "pages_mobile/general";
@import "pages_mobile/home";
@import "pages_mobile/about";
@import "pages_mobile/popup";
@import "pages_mobile/business";
@import "pages_mobile/sustainability";
@import "pages_mobile/news";
@import "pages_mobile/career";
@import "pages_mobile/misc";