.business-pillar{
	display: flex;
	height: calc(100vh - 9.0rem); 
	.text{
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding:4rem 8.5rem;

		.inner-text{
			width: 40rem;
		}
	}
	figure{
		width: 50%;
		img{
			width: 100%; height: 100%; object-fit: cover; object-position: center;
 		}
	}

	&.type-2{
		.text{
			order: 2;
			justify-content: flex-start;
		}
		figure{
			order: 1;
		}
	}
}

.light-text{
	margin-top: 25rem;
	h6{margin-bottom: 1rem;}
	p,a{color: $color-secondary;}
	p{
		font-size: 1.2rem;
		line-height: 1.5;
	}

	/* &.mid-pad{
		margin-top: 5rem;
	} */
}