@media all and (max-width: 1300px) {
	.about-figure{
		.side-content{
			width: 74.4rem;

			.title-side{
				flex-basis: 43%;
				max-width: 43%;
			}
			.content-side{
				flex-basis: 50%;
				max-width: 50%;
				padding-top: 7rem;

				&:before{
					left: -10rem;
					width: 40rem;
				}
			}
		}
	}
	.committee-list{
		.column{
			&.column-33{
				flex-basis: 50%;
				max-width: 50%;
			}
		}
	}
	.awards-section{
		.wrapper-middle{
			width: 72rem;
		}
	}
	.milestone-side{		
		padding-top: 0; padding-bottom: 0; margin-bottom: 70px;
		.list{
			margin-bottom: 9rem;
			h3{font-size: 2.5rem;}
			&:last-child {
				figcaption {
					p {margin-bottom: 0;}
				}
			}
		}
	}
	.about-vision{
		.row{
			flex-wrap: wrap;

			.column{
				&.column-31,&.column-61{
					flex-basis: 100%;
					max-width: 100%;
				}
			}

			&.row-pos-right{
				.column{
					flex-basis: 100%;
					max-width: 100%;
				}
			}
		}
	}

	.box-values{
		.list{
			flex-wrap: wrap; justify-content: unset; 
			h4 {font-size: 2.2rem;} 
			figcaption{
				flex-basis: 0;
				max-width: 75%;
				padding-left: 2rem;
				box-sizing: border-box;
				word-break: break-all;
			}
		}
	}
}

@media all and (max-width: 1020px) {
	.about-figure{
		.side-content{
			width: 100%;
			max-width: 100%;
			padding: 0 3.2rem;
		}
	}
	.milestone-side{

		&:before{
			right:auto;
			left: 4rem;
		}
		.list{
			flex-wrap: wrap;
			padding-left: 13rem;
			position: relative;

			&:before{
				content: "";
			    width: 3rem;
			    height: 3rem;
			    background: #fff;
			    border-radius: 50%;
			    border: .1rem solid #ECEDED;
			    position: absolute;
			    top: 13rem;
			    left: 2.5rem;
			}
			&:after{
				content: "";
			    width: 1.2rem;
			    height: 1.2rem;
			    background: #4D4D4D;
			    border-radius: 50%;
			    position: absolute;
			    top: 13.9rem;
			    left: 3.4rem;
			}

			figure, figcaption{
				flex-basis: 100%;
				max-width: 100%;
			}
			figure{
				margin-bottom: 2rem;
			}
			figcaption{
				&:before,&:after{display: none;}
			}

			&:nth-child(even){
				figure{order: 1;}
				figcaption{
					order: 2;
					text-align: left;
				}
			}
		}
	}
}