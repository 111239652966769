@media all and (max-width: 1300px) {
	.job-list{
		.list{
			padding-left: 0;
		}
	}

	.career-form{
		.wrapper-middle{
			width: 57.2rem;
		}
		.row{
			flex-wrap: wrap;
			
			.column{
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}
}