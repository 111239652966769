.sus4{
	.list{
		position: relative;
		width: 25%;
		display: block;

		figure{
			position: relative;
			overflow: hidden;

			&:before{
				content:"";
				background: rgba(0,0,0,0.7);
				z-index: 1;
				opacity: 0;
				@include position(absolute, 0);
				@include transition(.2s all ease-out);
			}
			img{
				width: 100%;
				@include transition(.2s all ease-out);
			}
		}
		figcaption{
			color: $color-initial;
			z-index: 2;
			@include position(absolute, null 3.2rem 4rem);

			h6,h4{color: $color-initial;margin-bottom: 1rem;}
			h4{font-family: $trajan;}
		}

		&:hover{
			figure{
				&:before{opacity:1;}
				img{
					transform: scale(1.05);
				}
			}
		}
	}
	.slick-arrow{
		width: 3rem;
		height: 6rem;
		padding: 0;
		border: none;
		min-width: 0;
		background: url('../images/material/arrow-light-slider.svg')no-repeat center;
		background-size: 100%;
		font-size: 0;
		color: transparent;
		z-index: 1;
		margin: auto 0;
		@include position(absolute, 0 null 0 null);

		&:before{display: none;}
		&.slick-prev{
			left: 4rem;
		}
		&.slick-next{
			right: 4rem;
			transform: rotate(180deg);
		}
	}
	.slick-dots{
		margin: 0;
		text-align: center;
		@include position(absolute, null 0 0);

		li{
			display: inline-block;
			margin: 0 1rem;
			vertical-align: middle;

			button{
				font-size: 0;
				color: transparent;
				padding: 0;
				margin: 0;
				width: 1rem;
				height: 1rem;
				min-width: 0;
				border-radius: 50%;
				border: .1rem solid $color-initial;
				background: rgba(0,0,0,.5);

				&:before{display:none;}
			}

			&.slick-active{
				button{
					width: 1.5rem;
					height: 1.5rem;
					margin-top: -.4rem;
					background: $color-initial;
				}
			}
		}
	}
}

.side-urban{
	border-bottom: .1rem solid #EAEAEA;
	.list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8rem;

		figure{
			flex: 1 0 40%;
			max-width: 40%;
			@extend .shadow;
			img {
				width: 100%; 
			}
		}
		figcaption{
			flex: 1 0 51%;
			max-width: 51%;
		}

		&:nth-child(even){
			figure{order:2;}
			figcaption{order:1;}
		}
	}
}