@media all and (max-width: 740px) {
	.sus4{
		.list{
			flex-basis: 100%;
			max-width: 100%;

			figure{
				img{
					height: 35rem;
				}
			}
			figcaption{bottom: 3rem;}
		}
		.slick-dots{
			bottom: -.7rem;
			li{
				margin: 0 .7rem;
			}
		}
		.slick-arrow { width: 1.5rem; }
	}
	.side-urban{
		.list{
			flex-wrap: wrap;
			margin-bottom: 8rem;
			figure,figcaption{
				flex-basis: 100%;
				max-width: 100%;
			}
			figure{order: 2;}
			figcaption{order: 1;margin-bottom: 2rem;}
		}
	}
}