@media all and (max-width: 1300px) {
	.about-home{
		padding: 8rem 3rem;
	}
	.quotes-home{
		min-height: 40rem;
		padding: 10rem;
		box-sizing: border-box;
	}
	.quality-home{
		.right-quality-home{
			padding-left: 5rem;
		}
	}
	.slider-quality-thumb{
		.slick-slide{
			img{width: 5.8rem;}
			&.slick-current{
				img{width: 7.5rem;}
			}
		}
	}
	.slider-product {
		figure {
			&:before {
				width:81rem; height: 81rem; 
			}
			&:after {
				width:65rem; height: 65rem; 
			}
		}
	}
	.slider-quality-large .slick-arrow { bottom: 43px; }
}
@media all and (max-width: 1020px) {
	.about-home{
		padding-left: 10rem;
		padding-right: 10rem;
		height: auto;

		&:after{width: 16rem;}
		.wrapper{
			flex-wrap: wrap;

			.left-about-home, .right-about-home{
				width: 100%;
			}
			.left-about-home{
				margin-bottom: 4rem;
				padding-right: 12rem;
			}
		}
	}
	.quotes-home{
		h3{font-size: 2rem;}
	}
	.quality-home{
		flex-wrap: wrap;
		height: auto; 
		.left-quality-home,.right-quality-home{
			flex-basis: 100%;
			max-width: 100%;
		}
		.left-quality-home{
			order: 2;
		}
		.right-quality-home{
			order: 1;
			padding: 8rem 10rem 6rem;

			.inner{max-width: none;}
		}
	}
	.slider-product {
		figure {
			&:before {
				width:750px; height: 750px; 
			}
			&:after {
				width:600px; height: 600px; 
			}
		}
	}
	.product-home {
		.indicator-slider-product {
			margin-top: 0;
		}
		.text-slider-product {margin-top: 0;}
	}
}

