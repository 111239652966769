@media all and (max-width: 740px) {
	.job-list{
		.list{
			flex-wrap: wrap;
			padding: 2rem 4rem 2rem 0;
			> div{
				flex: 1 0 100%;
				max-width: 100%;
			}
		}
	}
	.career-form{
		.wrapper-middle{
			width: 100%;
		}
	}
	.detail-job{
		.list{
			padding-left: 0;
			.number{
				display: block;
				margin-bottom: 2rem;
				top: auto;
				left: auto;
				position: relative;
			}
		}
	}
}