.public-list{
	margin-bottom: 3.5rem;

	h4{
		font-family: $trajan;
		margin-bottom: 0;
		font-size: 1.8rem;
	}
	a{
		display: block;
		padding: 3rem 3rem 3rem 0;
		border-top: .1rem solid #EAEAEA;
		position: relative;

		&:before{
			content:"";
			width: 2.5rem;
			background: url('../images/material/arrow-right.svg')no-repeat center;
			@include position(absolute, 0 0 0 null);
		}

		&:last-child{
			border-bottom: .1rem solid #EAEAEA;
		}
	}
}

.slider-gallery{
	position: relative;

	figcaption{
		padding: 2.3rem 7rem;

		h5{
			font-size: 1.6rem;
			color: #4D4D4D;
			margin-bottom: .5rem;
		}
		span{
			font-weight: 700;
			font-size: 1.2rem;
			display: block;
		}
	}

	.slick-arrow{
		width: 4rem;
		height: 4rem;
		padding: 0;
		border: none;
		min-width: 0;
		background: #242424 url('../images/material/arrow-light-slider.svg')no-repeat center;
		font-size: 0;
		color: transparent;
		z-index: 1;
		@include position(absolute, null null 2.2rem null);

		&:before{display: none;}
		&.slick-prev{
			left: 0;
		}
		&.slick-next{
			right: 0;
			transform: rotate(180deg);
		}
	}
}