@media all and (max-width: 1300px) {
	.business-pillar{
		.text{
			.inner-text{
				h3{font-size:2rem;}
			}
		}
	}

	.light-text{
		margin-top: 0;
		&.mid-pad{margin-top: 0;}
	}
}


@media all and (max-width: 1020px) {
	.business-pillar{
		figure{
			img{
				height: 100%;
				object-fit: cover;
			}
		}
		.text{
			padding: 4rem 7rem;
		}
	}
}